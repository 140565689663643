import React from 'react'
import { SERVER_DATE_FORMAT } from 'helpers'
import { format } from 'helpers/date'
import { computed, observable } from 'mobx'
import moment from 'moment'
import { Casts, Model, Store } from 'store/Base'
import { BusinessRelation } from './BusinessRelation'
import { ExactPurchaseOrder } from './Exact/PurchaseOrder'
import { Unit4PurchaseOrder } from './Unit4/PurchaseOrder'
import { InShipmentStore } from './InShipment'
import { PurchaseOrderLineStore } from './PurchaseOrderLine'
import { Warehouse } from 'store/Warehouse'
import { SalesOrder } from 'store/SalesOrder'
import { ShippingMethod } from './ShippingMethod'
import { ExactGlobePurchaseOrder } from './ExactGlobe/PurchaseOrder'
import { ProductionOrder } from './ProductionOrder'
import { isFeatureFlagEnabled } from 'helpers/featureFlags'
import { Link } from 'react-router-dom'
import { Popup } from 'semantic-ui-react'
import { StyledIcon, StyledLabel } from '../component/Labels';

export const STATUS_OPEN = 'open'
export const STATUS_PARTIAL = 'partial'
export const STATUS_COMPLETE = 'complete'
export const STATUS_CANCELED = 'canceled'
export const STATUSES = [STATUS_OPEN, STATUS_PARTIAL, STATUS_COMPLETE, STATUS_CANCELED]
export const SOURCES = [
  'Manual entry', 'Import', 'Other', 'Purchase order', 'Sales order', 'Supplier\'s items',
  'Subcontract', 'Purchase order advice', 'Shop order', 'MRP calculation', 'Rest API',
  'Merge purchase orders',
]

export class PurchaseOrder extends Model {
  static backendResourceName = 'purchase_order'
  static idPrefix = 'PU'
  static idColor = 'violet'
  static idIcon = 'shopping cart'

  getUrl() {
    return `/warehouse/inbound/order/${this.id}?.id=${this.id}`;
  }

  getMiniTag() {
    return window.viewStore.tracyTagSize === 'mini'
  }

  @observable id = null
  @observable status = STATUS_OPEN
  @observable source = 'Manual entry'
  @observable externalSyncDatetime = null
  @observable externalSyncFailureReason = null
  @observable reference = ''
  @observable orderNumberErp = ''
  @observable orderDate = null
  @observable receiptDate = null
  @observable deleted = false
  @observable markedColors = []

  @observable supplier = this.relation(BusinessRelation)
  @observable lines = this.relation(PurchaseOrderLineStore)
  @observable inShipments = this.relation(InShipmentStore)
  @observable exactPurchaseOrder = this.relation(ExactPurchaseOrder)
  @observable unit4PurchaseOrder = this.relation(Unit4PurchaseOrder)
  @observable exactGlobePurchaseOrder = this.relation(ExactGlobePurchaseOrder)
  @observable warehouse = this.relation(Warehouse)
  @observable salesOrder = this.relation(SalesOrder)
  @observable shippingMethod = this.relation(ShippingMethod)
  @observable productionOrder = this.relation(ProductionOrder)

  casts() {
    return {
      externalSyncDatetime: Casts.datetime,
      orderDate: Casts.date,
      receiptDate: Casts.date,
    }
  }

  getLinkedAllocatedSalesOrders(progressViewLink = false) {
    const linkedSas = []

    if (!this.salesOrder?.isNew) {
      linkedSas.push(this.salesOrder)
    }

    if (this.lines) {
      if (this.lines.filter(line => line.salesOrderLine && !line.salesOrderLine.isNew).length > 0) {
        this.lines.filter(line => line.salesOrderLine && !line.salesOrderLine.isNew).forEach(line => {
          if (!linkedSas.map(sa => sa.id).includes(line.salesOrderLine.salesOrder.id)) {
            linkedSas.push(line.salesOrderLine.salesOrder)
          }
        })
      }

      if (isFeatureFlagEnabled('allocations') && this.lines.filter(line => line.resourceAllocations).length > 0) {
        this.lines.forEach((line) => {
          line.resourceAllocations.filter(ra => ra.salesOrderLine && !ra.salesOrderLine.isNew && ra.salesOrderLine.salesOrder.orderNumberErp !== null).forEach((resourceAlloc) => {
            if (!linkedSas.map(sa => sa.id).includes(resourceAlloc.salesOrderLine.salesOrder.id)) {
              linkedSas.push(resourceAlloc.salesOrderLine.salesOrder)
            }
          })
        })

      }
    }

    return linkedSas.map(sa => sa.getIdLabel(false, { progressViewLink: true }))
  }

  getErpLink() {
    if (this.orderNumberErp === null) {
      return
    }

    return (
      <Popup
        trigger={
          <StyledLabel
            color={'red'}
            data-test-tag-linked-purchase-order={this.id}
            as={Link}
            to={this.getUrl()}
            style={{ textDecoration: 'none', margin: '0' }}
            target={'_blank'}
          >
            <StyledIcon name={PurchaseOrder.idIcon} />
            {this.orderNumberErp}
          </StyledLabel>
        }
        content={<>{t('purchaseOrder.overview.erpNumber')}:{' '} {this.orderNumberErp || this.id}</>}
      />
    )
  }

  getErpLinks() {
    return <>
      {!this.exactPurchaseOrder?.isNew && this.exactPurchaseOrder?.getLink()}
      {!this.unit4Order?.isNew && this.unit4Order?.getLink()}
      {!this.navisionPurchaseOrder?.isNew && this.navisionPurchaseOrder?.getLink()}
      {!this.exactGlobePurchaseOrder?.isNew && this.exactGlobePurchaseOrder?.getLink()}
    </>
  }

  /**
   * Get salesOrderLinks for all allocations made to lines from inside tracy
   * Requires featureflag 'annotations'
   *
   * @returns array of erp labels
   */
  getAllocatedSalesOrderErpLabels() {
    if (this.lines && this.lines.filter(line => line.resourceAllocations).length > 0) {
      const linkedResourceAllocSAs = []
      this.lines.forEach((line) => {
        line.resourceAllocations.filter(ra => ra.salesOrderLine && !ra.salesOrderLine.isNew && ra.salesOrderLine.salesOrder.orderNumberErp !== null).forEach((resourceAlloc) => {
          if (!linkedResourceAllocSAs.map(sa => sa.id).includes(resourceAlloc.salesOrderLine.salesOrder.id)) {
            linkedResourceAllocSAs.push(resourceAlloc.salesOrderLine.salesOrder)
          }
        })
      })

      return linkedResourceAllocSAs.map(sa => sa.getIdLabel())
    }
  }

  getLinkedSalesOrderLabel() {
    const salesOrder = this.salesOrder

    if (this.lines && this.lines.filter(line => line.salesOrderLine && !line.salesOrderLine.isNew).length > 0) {
      const linkedSAs = []
      this.lines.filter(line => line.salesOrderLine && !line.salesOrderLine.isNew).forEach(line => {
        if (!linkedSAs.map(sa => sa.id).includes(line.salesOrderLine.salesOrder.id)) {
          linkedSAs.push(line.salesOrderLine.salesOrder)
        }
      })

      return linkedSAs.map(sa => sa.getIdLabel(false, { progressViewLink: true }))
    }

    if (!salesOrder || salesOrder.isNew) {
      return null
    }

    return salesOrder.getIdLabel(false, { progressViewLink: true })
  }

  getLinkedProductionOrderLabel() {
    const productionOrder = this.productionOrder

    if (this.lines && this.lines.filter(line => line.productionOrder && !line.productionOrder.isNew).length > 0) {
      const linkedPos = []
      this.lines.filter(line => line.productionOrder && !line.productionOrder.isNew).forEach(line => {
        if (!linkedPos.map(po => po.id).includes(line.productionOrder.id)) {
          linkedPos.push(line.productionOrder)
        }
      })

      return linkedPos.map(po => po.getErpLink())
    }

    if (!productionOrder || productionOrder.isNew) {
      return null
    }

    return productionOrder.getErpLink()
  }

  @computed
  get quantity() {
    let quantity = 0

    if (this.lines) {
      quantity = this.lines.models.reduce((res, l) => res + l.quantity, quantity)
    }

    return quantity
  }

  @computed
  get _status() {
    return t(`purchaseOrder.field.status.value.${this.status}`)
  }

  @computed
  get planningStatus() {
    const today = moment().format(SERVER_DATE_FORMAT)

    const states = []

    // red: Overdue Receipts. Receipt date overdue, no shipment paired.
    if (format(this.receiptDate, SERVER_DATE_FORMAT) < today && this.inShipments.length === 0) {
      states.push('overdue_receipts')
    }

    // orange: Overdue Requests. Shipements overdue (order with shipment paired that is overdue).
    if (format(this.receiptDate, SERVER_DATE_FORMAT) < today && this.inShipments.length > 0) {
      states.push('overdue_requests')
    }

    // green: Receipts This week. Shipments coming this week.
    if (moment(this.receiptDate).isSame(new Date(), 'week')) {
      states.push('receipts_this_week')
    }

    // gray: Future Receipts. Shipments upcoming after this week.
    if (moment(this.receiptDate).isAfter(new Date(), 'week')) {
      states.push('future_receipts')
    }

    return states

    // let status = 'normal';

    // if (
    //     format(this.receiptDate, SERVER_DATE_FORMAT) < today &&
    //     [STATUS_OPEN, STATUS_PARTIAL].includes(this.status)
    // ) {
    //     if (this.inShipments && this.inShipments.length === 0) {
    //         status = 'danger';
    //     } else {
    //         status = 'warning';
    //     }
    // }

    // return status;
  }

  toBackendAll(...args) {
    const data = super.toBackendAll(...args)

    // eslint-disable-next-line
    for (const in_shipment_line of data.relations.in_shipment_line || []) {
      if (in_shipment_line.id >= 0) {
        continue
      }
      const in_shipment = data.relations.in_shipment.find(({ lines }) => lines.includes(in_shipment_line.id))
      const production_request = data.relations.production_request.find(({ id }) => id === in_shipment_line.production_request)
      production_request.superrequest = in_shipment.production_request
    }

    return data
  }
}

export class PurchaseOrderStore extends Store {
  static backendResourceName = 'purchase_order'
  Model = PurchaseOrder

  @observable _statusTotals = null
  @observable _requestStatusTotals = null
  @observable _receiptStatusTotals = null

  fetchStatusTotals({ mapParams = (params) => params } = {}) {
    return this.api.get(this.url() + 'status_totals/', mapParams(this.params)).then((res) => {
      this._requestStatusTotals = res.data.request_status
      this._receiptStatusTotals = res.data.receipt_status
      this._statusTotals = res.data.status
    })
  }
}
