import { observable } from 'mobx'
import { Model, Store } from 'store/Base'


export class ItemAssortmentValue extends Model {
  static backendResourceName = 'item_assortment_value'

  @observable id = null
  @observable code = ''
  @observable description = ''

}

export class ItemAssortmentValueStore extends Store {
  Model = ItemAssortmentValue
  static backendResourceName = 'item_assortment_value'
}
