import React from 'react'
import { observable, action, reaction, computed } from 'mobx'
import { observer } from 'mobx-react'
import {
  ProductionRequestProgress,
  ProductionLineRow,
  ProductionRequestModal,
} from 'container/Progress/Overview'
import { DateTime } from 'luxon'
import { TargetSelect, serializeWheres } from '@code-yellow/spider'
import { Popup, Table, Icon, Label, Divider, Checkbox, Button, Form } from 'semantic-ui-react'
import FindBatchOrArticleTypeModal from 'container/FindBatchOrArticleTypeModal'
import ScanToPerformModal from 'container/ScanToPerformModal'
import styled from 'styled-components'
import ProgressLinesDetails from 'screen/Progress/OrderLines'
import AddStockCountModal from '../AddStockCount';
import TimingGanttChart from 'container/Progress/TimingGanttChart';
import ProductionRequestReduceQuantityModal from 'component/ProductionRequestReduceQuantityModal'

// component
import AdminOverview, { ItemButton } from 'component/AdminOverview'
import TargetInfoModal from 'component/TargetInfoModal'
import ArticleTypeInfoModal from 'component/ArticleTypeInfoModal'
import trafficLights from 'component/TrafficLights'
import { Body2, Body3, Caption1, FullContent, COLORS } from 'component/PerformanceStyles';
import LiveProgress from 'component/Progress/LiveProgress'
import { ProductionRequestDrawingImage } from 'component/ProductionRequest/DrawingImage'
import PlannedOnInformation from 'component/ProductionRequest/PlannedOnInformation';
import ColorMarkingLabelContainer, { markedColorFilter, markedColorReactionMap } from 'component/ProductionRequest/MarkedColor';
import NestRequestDetails from 'component/NestRequestDetails'
// end component

// helpers
import { range } from 'helpers'
import { formatPeriod, getWorkStationsFte } from 'screen/Planner/helpers'
import { workStationActiveOrders } from 'helpers/getActiveOrdersPerWorkstation'
import { getArticleTypeName, getExactShopOrders, getExactGlobeProductionOrders } from 'helpers/productionRequest';
import getLink from 'helpers/getLink'
import { isFeatureFlagEnabled } from 'helpers/featureFlags';
import getGlobalValue from 'helpers/getGlobalValue'
// end helpers

// stores
import { ProjectStore } from 'store/Project'
import { ClassificationStore } from 'store/Classification'
import { ProductionRequestStore, MARKED_COLORS } from 'store/ProductionRequest'
import { ProductionLineStore } from 'store/ProductionLine'
import { ShippingMethodStore } from 'store/ShippingMethod'
import { Batch } from 'store/Batch'
import { ArticleType } from 'store/ArticleType'
import { BusinessRelationStore } from 'store/BusinessRelation'
import { PROCESS_TYPES } from 'store/BatchType'
import { Operator } from 'store/Operator'
import { GlobalValue } from 'store/GlobalValue'
// end stores

const GroupRow = styled.tr`
  background-color: #EEEEEE !important;
  ${({ open }) => open && `
    border-bottom: 6px solid ${COLORS.grey800};
  `}
`

const OrderTagBox = styled.div`
  display: inline-block;
  min-height: 40px;
  > div {
    margin-left: 0.142857em;
    display: inline-block;
  }
  > * {
    vertical-align: top !important;
  }
`

// const StyledTargetNumberInput = styled(TargetNumberInput)`
//   width: 10.45em;
//   > .ui.input > input {
//     min-width: 0;
//     flex: 1 1 0;
//   }
// `

const ButtonField = styled(Form.Field)`
  > .ui.button {
    margin-bottom: 0.5em !important;
    &:last-child {
      margin-bottom: 0 !important;
    }
  }
`

const NOTES_MAX_CHARACTERS = 50;

const GROUP_BY_KEY = 'progress-overview-group-by'
const SHOW_STEP_LABELS_KEY = 'progress-overview-show-steps-labels'

function currentWeek() {
  const date = DateTime.local()
  return { year: date.weekYear, week: date.weekNumber }
}

function currentMonth() {
  const date = DateTime.local()
  return { year: date.year, month: date.month }
}

function currentDate() {
  const date = DateTime.local()
  return { year: date.year, month: date.month, day: date.day }
}

export function notesFormatter(value, format_limit) {
  if (!format_limit) {
    format_limit = NOTES_MAX_CHARACTERS
  }

  if (value.length <= format_limit) {
    return value
  } else {
    const index = value.lastIndexOf(' ', format_limit)
    return (
      <Popup data-test-notes-popup
        content={value}
        trigger={
          <Body3>
            {value.substring(0, index !== -1 ? index : format_limit)} {'...'}
          </Body3>
        }
      />
    )
  }
}

function getTransferDetails(warehouseTransfer) {
  if (warehouseTransfer.isNew) {
    return null
  }
  const details = {
    type: warehouseTransfer.type,
    from: null,
    to: null,
  }
  if (details.type === 'Warehouse transfer') {
    details.from = warehouseTransfer.warehouseFrom.code
    details.to = warehouseTransfer.warehouseTo.code
  }
  return details
}

function combineNotes(notes, linkedSaNotes) {
  return notes + (notes.length > 0 && linkedSaNotes.length > 0 ? ' | ' : '') + linkedSaNotes
}

function getPONotes(productionOrder) {
  var notes = productionOrder.remarks ?? ''
  if (!productionOrder.salesOrderLine) {
    return notes
  }

  if (!productionOrder.salesOrderLine.isNew) {
    notes = combineNotes(notes, productionOrder.salesOrderLine.notes)
  }
  return notes
}

export function getNotes(productionRequest) {
  if (productionRequest.outShipmentLine !== null && !productionRequest.outShipmentLine.isNew) {
    return productionRequest.outShipmentLine.salesOrderLine.notes
  } else if (productionRequest.inShipmentLine !== null && !productionRequest.inShipmentLine?.isNew) {
    var notes = productionRequest.inShipmentLine.purchaseOrderLine.notes ?? ''
    if (!productionRequest.inShipmentLine.purchaseOrderLine.salesOrderLine.isNew) {
      notes = combineNotes(notes, productionRequest.inShipmentLine.purchaseOrderLine.salesOrderLine.notes)
    }
    return notes
  } else if (productionRequest.productionOrder !== null && !productionRequest.productionOrder.isNew) {
    return getPONotes(productionRequest.productionOrder)
  }
  return null
}

export const BASE_RELATIONS = [
  'batches.batchType',
  'batches.batchUsings',
  'batches.lastStep.nextStep',

  'nestRequest',
  'productionOrder',
  'project',

  'processVersion.steps.workStation.productionLineVersion.productionLine',
  'processVersion.steps.nextStep',
  'processVersion.steps.formStep',
  'processVersion.steps.multiplierStep',
  'processVersion.steps.splitStep',
  'processVersion.steps.printStep',
  'processVersion.steps.exportStep.integration.radanIntegration',
  'processVersion.steps.importStep.integration.radanIntegration',
  'processVersion.batchType.articleType.exactItem',
]

export const BASE_WHERES = {
  batches: {
    'batch_type.type:not': 'component',
    'finalized_at:isnull': 'true',
    'quantity_remaining:gt': 0,
  },
}


@observer
export default class BaseProgressOverviewScreen extends AdminOverview {

  constructor(...args) {
    super(...args)
    this.renderReceiptStatusButton = this.renderReceiptStatusButton.bind(this)
    this.onProductionRequestAdd = this.onProductionRequestAdd.bind(this)
    this.onProductionRequestChange = this.onProductionRequestChange.bind(this)
    this.onProductionRequestDelete = this.onProductionRequestDelete.bind(this)
    this.afterFetch = this.afterFetch.bind(this)

    this.getDecimalRounding()

    let urlParams = new URLSearchParams(window.location.search);
    urlParams = Object.fromEntries(Array.from(urlParams.entries()));

    if (urlParams['.open']) {
      let changed = false

      if (window.viewStore.progressScope === 'week' && !/^\d{4}-W\d{1,2}$/.test(urlParams['.open'])) {
        delete urlParams['.open']
        changed = true
      }

      if (window.viewStore.progressScope === 'month' && !/^\d{4}-\d{2}$/.test(urlParams['.open'])) {
        delete urlParams['.open']
        changed = true
      }

      if (window.viewStore.progressScope === 'day' && !/^\d{4}-\d{2}-\d{2}$/.test(urlParams['.open'])) {
        delete urlParams['.open']
        changed = true
      }


      if (changed) {
        const paramString = new URLSearchParams(urlParams).toString()
        window.history.replaceState({}, '', `${window.location.pathname}${paramString === '' ? '' : '?'}${paramString}`)
      }
    }
  }

  /**
   * The code of workstation we are performing at. This can be null in case we are not a workstation
   * @type {null}
   */
  @observable workStationCode = null;

  @observable globalValueDecimalRounding = 3

  async getDecimalRounding() {
    this.globalValueDecimalRounding = await getGlobalValue('material_plan_decimal_rounding')
  }

  /**
   * A special fetch week function is called upon mount, which also sets
   * listeners to websockets.
   *
   * TODO: refactor so that the websocket listeners don't care about the week.
   */

  TableRow = ProductionLineRow
  Content = FullContent;

  productionLineStore = new ProductionLineStore({
    params: {
      order_by: 'name',
    },
  })

  businessRelations = new BusinessRelationStore()
  shippingMethods = new ShippingMethodStore()
  viewStore = this.props.viewStore
  title = t('progress.title')
  emptyMessage = t('progress.empty')
  //Make sure each admin workstation has a different filter key
  //If the workstaton is not an admin workstation or if it is an admin workstation viewed from a normal workstation,
  //do not add the id so we don't lose the previously setup filters
  myFilterKey = 'progress-overview' + ((!this.viewStore.isWorkStation && this.props.workStation?.isAdministration && !!this.props.workStation?.code) ? this.props.workStation?.code : '')
  myFilterBlacklist = ['.open']

  @observable selected = null

  @observable selectedOption = null;
  @observable selectedGroup = null
  @observable workStationsFte = null
  @observable groupBy = localStorage.getItem(GROUP_BY_KEY) || 'step'
  @observable batchOrArticleTypeToShowInfo = null
  @observable selectedProductionRequest = null
  @observable store = new ProductionRequestStore({
    relations: [
      ...BASE_RELATIONS,

      ...BASE_RELATIONS.map((rel) => `batches.subProductionRequestBatchLinks.subProductionRequest.${rel}`),

      'productionOrder.salesOrderLine.salesOrder',
      'productionOrder.exactShopOrder',
      'productionOrder.exactGlobeProductionOrder',
      'productionOrder.resourceAllocations.salesOrderLine.salesOrder.exactSalesOrder',

      // Traffic light calculation
      'productionOrder.warehouse',
      'productionOrder.purchaseOrderLines.purchaseOrder.exactGlobePurchaseOrder',
      'productionOrder.purchaseOrders.exactGlobePurchaseOrder',
      'productionOrder.materialPlan',

      'stockCount.warehouse',
      'stockCount.defaultStorageLocation',

      'inShipment.purchaseOrder.exactPurchaseOrder',
      'inShipment.purchaseOrder.unit4PurchaseOrder',
      'inShipment.purchaseOrder.exactGlobePurchaseOrder',
      'inShipment.purchaseOrder.supplier',
      'inShipment.purchaseOrder.shippingMethod',
      'inShipment.purchaseOrder.salesOrder.exactSalesOrder',
      'inShipment.lines',

      'outShipment.salesOrder.exactSalesOrder',
      'outShipment.salesOrder.unit4Order',
      'outShipment.salesOrder.exactGlobeSalesOrder',
      'outShipment.salesOrder.customer',
      'outShipment.salesOrder.shippingMethod',
      'outShipment.lines',

      'warehouseTransfer.warehouseFrom',
      'warehouseTransfer.warehouseTo',
      'warehouseTransfer.lines',

      'nests.nestType.articleType',

      'superProductionRequestLinks.superProductionRequestBatch.productionRequest.productionOrder.exactShopOrder',
      'superProductionRequestLinks.superProductionRequestBatch.productionRequest.productionOrder.exactGlobeProductionOrder',
    ],
    params: {
      include_annotations: '*,production_line_names,production_line_ids,out_shipment.lines(*,quantity_received_erp),inbound_traffic_light,outbound_traffic_light,material_plan_traffic_light,capacity_traffic_light',
      where: serializeWheres(BASE_WHERES),
    },
  })

  params = {
    '.request_status:in': 'todo,in_progress',
    '.released': 'true',
  }

  @observable classificationStore = new ClassificationStore()
  @observable showLabels = localStorage.getItem(SHOW_STEP_LABELS_KEY) || true

  setUrl(order) {
    const { history } = this.props;
    const workStationId = this.workStation?.id;

    let scope = '';
    if (workStationId) {
      scope = `/perform/${workStationId}/overview`;
    } else {
      const orderScope = order ? `/${order}` : '';
      scope = `/production-request/overview${orderScope}`;
    }
    history.replace(`/operations${scope}${window.location.search}`);
  }

  @computed get selectedId() {
    const { match } = this.props;
    const { order } = match.params;
    return order;
  }

  set selectedId(order) {
    this.setUrl(order);
  }

  @observable scannedOperator = null
  @observable scannedOperatorTimeout = null

  async getScannedOperator() {
    const id = parseInt(localStorage.getItem('scanned-operator'))
    if (id) {
      this.scannedOperator = new Operator({
        id: parseInt(localStorage.getItem('scanned-operator'))
      }, {
        relations: ['operatorCapabilities.capability'],
      })
      await this.scannedOperator.fetch()
    }
  }

  renderTitle() {
    let title;
    if (!this.store.isLoading && ('.nest_request.nest_id:icontains' in this.store.params || '.flat_nest_id' in this.store.params)) {
      title = t('progress.titleNests', {
        open: this.store.models.filter(productionRequest => productionRequest.requestStatus === 'todo').length,
        inProgress: this.store.models.filter(productionRequest => productionRequest.requestStatus === 'in_progress').length,
      });
    } else {
      title = t('progress.title');
    }

    return <this.HeaderRight as="h1" content={title}>{this.renderTitleRight()}</this.HeaderRight>;
  }

  tableProps() {
    return { 'data-test-progress': true }
  }

  rowProps(productionRequest, i) {
    return {
      ...super.rowProps(productionRequest, i),
      onClick: () => {
        if (productionRequest.id === this.selected?.id) {
          this.selected = null
          this.selectedId = null
          this.selectedOption = null;
        } else if (['inShipment', 'outShipment', 'warehouseTransfer'].some((details) => productionRequest[details].lines.length > 0)) {
          this.selected = productionRequest
        } else if (productionRequest.batches.models.some((batch) => batch.subProductionRequestBatchLinks.length > 0)) {
          this.getSubProductionRequestLinks(productionRequest)
        }
      },
      'data-test-production-request': productionRequest.id,
      warning: this.prWarning(productionRequest),
    }
  }

  getSubProductionRequestLinks(productionRequest) {
    let store = null

    // eslint-disable-next-line
    for (const batch of productionRequest.batches.models) {
      if (batch.subProductionRequestBatchLinks.isNew) {
        return
      }

      store = new ProductionRequestStore({
        relations: [
          'inShipmentLine', 'outShipmentLine', 'warehouseTransferLine', 'nestRequest',
          ...BASE_RELATIONS,
        ]
      })
      store.parse(batch.subProductionRequestBatchLinks.map((link) => {
        // eslint-disable-next-line
        const { subProductionRequest, ...linkData } = link.toJS()
        return { ...subProductionRequest }
      }))

      this.selected = { id: productionRequest.id, store }

      return
    }
  }

  prWarning(productionRequest) {
    return (
      (this.week && (productionRequest.startAt.isoWeekYear() !== this.week.year || productionRequest.startAt.isoWeek() !== this.week.week)) ||
      (this.month && (productionRequest.startAt.year() !== this.month.year || productionRequest.startAt.month() !== this.month.month))
    )
  }

  onStartPerforming(productionRequest) {
    this.props.history.push(`/operations/production-request/${productionRequest.id}/perform/${this.workStationCode}?prev=${encodeURIComponent(window.location.pathname + window.location.search)}`)
  }

  // Allows us to reuse them when overriding this.settings
  baseSettings() {
    // [T41658] Only show traffic lights if there is a scope(week, month, day) selected,
    // and when it is not a workstation view.
    const showOrderTrafficLights = !this.workStationCode && this.period !== null

    return [
      {
        label: null,
        attr: (productionRequest) => (
          (
            ['inShipment', 'outShipment', 'warehouseTransfer'].some((details) => productionRequest[details].lines.length > 0) ||
            (productionRequest.batches.models.some((batch) => batch.subProductionRequestBatchLinks.length > 0))
          ) &&
          <Icon data-test-chevron name={productionRequest.id === this.selected?.id ? 'chevron down' : 'chevron right'} />
        ),
        collapsing: true,
      },
      {
        label: null,
        attr: (productionRequest) => (
          <Icon data-test-flagged={productionRequest.id}
            name={productionRequest.flagged ? 'flag' : 'flag outline'}
            color={productionRequest.flagged ? 'red' : '#E0E0E0'}
          />),
        collapsing: true,
      },
      {
        attr: (productionRequest) => {
          const { viewStore } = this.props

          const inShipment = productionRequest.inShipment
          const purchaseOrder = inShipment.purchaseOrder
          const exactPurchaseOrder = purchaseOrder.exactPurchaseOrder
          const unit4PurchaseOrder = purchaseOrder.unit4PurchaseOrder
          const exactGlobePurchaseOrder = purchaseOrder.exactGlobePurchaseOrder

          const outShipment = productionRequest.outShipment
          const salesOrder = outShipment.salesOrder
          const exactSalesOrder = salesOrder.exactSalesOrder
          const unit4Order = salesOrder.unit4Order
          const exactGlobeSalesOrder = salesOrder.exactGlobeSalesOrder

          const transfer = productionRequest.warehouseTransfer

          const productionOrder = productionRequest.productionOrder
          const exactShopOrders = getExactShopOrders(productionRequest);
          const exactGlobeProductionOrders = getExactGlobeProductionOrders(productionRequest);

          const stockCount = productionRequest.stockCount

          const transferDetails = getTransferDetails(transfer)
          const description = productionOrder.description

          const pONotes = getPONotes(productionRequest.productionOrder)

          const nestRequest = productionRequest.nestRequest

          return (
            <>
              <OrderTagBox data-test-order-tags>
                {(!productionRequest.isNew) &&
                  getLink(productionRequest, viewStore.isWorkStation, {
                    hover: (
                      <>
                        {t('productionRequest.overview.productionRequestID')}: {productionRequest && productionRequest.id}{' '}
                        <br />
                        {t('productionRequest.field.project.label')}:{' '}
                        {productionRequest.project && productionRequest.project.code}
                      </>
                    ),
                  })
                }
                {!transfer.isNew && (
                  getLink(transfer, viewStore.isWorkStation, {
                    hover: (
                      <>
                        {t('productionRequest.overview.warehouseTransferID')}:{' '}
                        {transfer && transfer.id}
                      </>
                    ),
                  })
                )}
                {!inShipment.isNew &&
                  getLink(inShipment, viewStore.isWorkStation, {
                    hover: (
                      <>
                        {t('productionRequest.overview.inShipmentId')}:{' '}
                        {inShipment && inShipment.id}
                      </>
                    ),
                  })}
                {!purchaseOrder.isNew &&
                  <div>
                    {getLink(purchaseOrder, viewStore.isWorkStation, {
                      hover: (
                        <>
                          {t('productionRequest.overview.purchaseOrderNumber')}:{' '}
                          {purchaseOrder && purchaseOrder.id}{' '}
                        </>
                      ),
                    })}
                    {!purchaseOrder.isNew && purchaseOrder && <ColorMarkingLabelContainer target={purchaseOrder} colorMarkingNames={this.colorMarkingNames} />}
                    {!purchaseOrder.salesOrder.isNew && purchaseOrder.getLinkedSalesOrderLabel()}
                    {!purchaseOrder.salesOrder.isNew && purchaseOrder.salesOrder && <ColorMarkingLabelContainer target={purchaseOrder.salesOrder} />}
                  </div>
                }
                {!exactPurchaseOrder.isNew &&
                  getLink(exactPurchaseOrder, viewStore.isWorkStation, {
                    hover: (
                      <>{t('productionRequest.overview.exactPurchaseOrder', { order: exactPurchaseOrder.number })}</>
                    ),
                  })}
                {!unit4PurchaseOrder.isNew && getLink(unit4PurchaseOrder, viewStore.isWorkStation)}
                {!exactGlobePurchaseOrder.isNew && getLink(exactGlobePurchaseOrder, viewStore.isWorkStation)}
                {!outShipment.isNew &&
                  getLink(outShipment, viewStore.isWorkStation, {
                    hover: (
                      <>
                        {t('productionRequest.overview.outShipmentId')}:{' '}
                        {outShipment && outShipment.id}
                      </>
                    ),
                  })}
                {!salesOrder.isNew &&
                  <div>
                    {getLink(salesOrder, viewStore.isWorkStation, {
                      hover: (
                        <>
                          {t('productionRequest.overview.salesOrderNumber')}:{' '}
                          {salesOrder && salesOrder.id}
                        </>
                      ),
                    })}
                    {!salesOrder.isNew && salesOrder && <ColorMarkingLabelContainer target={salesOrder} colorMarkingNames={this.colorMarkingNames} />}
                  </div>
                }
                {!exactSalesOrder.isNew &&
                  getLink(exactSalesOrder, viewStore.isWorkStation, {
                    hover: <>{t('productionRequest.overview.exactSalesOrder', { order: exactSalesOrder.number })}</>,
                  })}
                {!unit4Order.isNew && getLink(unit4Order, viewStore.isWorkStation,)}
                {!exactGlobeSalesOrder.isNew && getLink(exactGlobeSalesOrder, viewStore.isWorkStation)}
                {!productionOrder.isNew &&
                  <div>
                    {getLink(productionOrder, viewStore.isWorkStation, {
                      hover: (
                        <>
                          {t('productionRequest.overview.productionOrderNumber')}:{' '}
                          {productionOrder && productionOrder.id}
                        </>
                      ),
                    })}
                    <ColorMarkingLabelContainer target={productionRequest} colorMarkingNames={this.colorMarkingNames} />
                  </div>
                }
                {exactShopOrders.map((exactShopOrder) => (
                  <React.Fragment key={exactShopOrder.cid}>
                    {getLink(exactShopOrder, viewStore.isWorkStation, {
                      hover: <>{t('productionRequest.overview.exactShopOrder', { order: exactShopOrder.number })}</>,
                    })}
                  </React.Fragment>
                ))}
                {exactGlobeProductionOrders.map((exactGlobeProductionOrder) => (
                  <React.Fragment key={exactGlobeProductionOrder.cid}>
                    {getLink(exactGlobeProductionOrder, viewStore.isWorkStation)}
                  </React.Fragment>
                ))}
                {!productionOrder.isNew && productionOrder.getLinkedAllocatedSalesOrders()}
                {!stockCount.isNew &&
                  getLink(stockCount, viewStore.isWorkStation, {
                    hover: (
                      <>
                        {t('productionRequest.overview.stockCountNumber')}:{' '}
                        {stockCount && stockCount.id}
                      </>
                    ),
                  })}
                {
                  (!productionRequest.nests.nestType.articleType.isNew && getLink(productionRequest.nests.nestType.articleType, viewStore.isWorkStation))
                  ||
                  (!productionRequest.processVersion.batchType.articleType.isNew && getLink(
                    productionRequest.processVersion.batchType.articleType,
                    viewStore.isWorkStation,
                    viewStore.isWorkStation ? {
                      onClick: () => {
                        this.store.params = {
                          ...this.store.params,
                          '.article_type_search': productionRequest.processVersion.batchType.articleType.code,
                        }
                        this.debouncedFetch()
                      }
                    } : {}
                  ))
                }
                {!productionRequest.nestRequest.isNew && productionRequest.nestRequest.getLabel(
                  {
                    onClick: () => {
                      this.store.params = {
                        ...this.store.params,
                        '.nest_request.nest_id:icontains': productionRequest.nestRequest.nestId,
                      }
                      this.debouncedFetch()
                    }
                  }
                )}
              </OrderTagBox>
              {!productionRequest.processVersion.batchType.articleType.isNew && (
                <Body2>
                  <br />
                  {getArticleTypeName(productionRequest)}
                </Body2>
              )}
              <br />
              {!purchaseOrder.isNew && (
                <>
                  <Body2>{purchaseOrder.supplier.name}</Body2>
                  <Caption1>{purchaseOrder.reference}</Caption1>
                  <Caption1>{purchaseOrder.shippingMethod.code}</Caption1>
                </>
              )}
              {!salesOrder.isNew && (
                <>
                  <Body2>{salesOrder.customer.name}</Body2>
                  <Caption1>{salesOrder.reference}</Caption1>
                  <Caption1>{salesOrder.shippingMethod.code}</Caption1>
                  {salesOrder.creatorFullName?.length > 0 && <Caption1>{t('productionRequest.overview.createdBy', { creator: salesOrder.creatorFullName })}</Caption1>}
                  {salesOrder.remarks.length > 0 && (
                    <>
                      <Caption1>{notesFormatter(salesOrder.remarks)}</Caption1>
                    </>
                  )}
                </>
              )}
              {transferDetails && (
                <>
                  <Body2>{transferDetails.type}</Body2>
                  {transferDetails.from && transferDetails.to && (
                    <>
                      <Caption1>From: {' '}{transferDetails.from}</Caption1>
                      <Caption1>To: {' '}{transferDetails.to}</Caption1>
                    </>
                  )}
                </>
              )}
              {!productionOrder.isNew && (
                <>
                  {description !== null && description !== '' && (
                    <Caption1>{description}</Caption1>
                  )}
                  {pONotes != null && <Caption1>{notesFormatter(pONotes)}</Caption1>}
                </>
              )}
              {!productionRequest.stockCount.isNew && (
                <>
                  <Body2>{productionRequest.stockCount.warehouse.code}</Body2>
                  <Caption1>{productionRequest.stockCount.defaultStorageLocation.code}</Caption1>
                </>
              )}
              {this.workStationCode && (
                <ProductionRequestDrawingImage productionRequest={productionRequest} size='mini' />
              )}
              {!nestRequest.isNew &&
                <NestRequestDetails nestRequest={nestRequest} isSmallDescription />
              }
            </>
          )
        },
        label: t('productionRequest.overview.order'),
        cellProps: { collapsing: true, singleLine: true, 'data-test-progress-tags': true },
      },
      ...(showOrderTrafficLights ? trafficLights(this.workStationsFte, this.period) : []),
      {
        label: 'Planned on',
        attr: (productionRequest) => {





          return (
            <div onClick={(event) => {
              if (!isFeatureFlagEnabled('process_timing')) {
                return;
              }
              this.selected = productionRequest;
              this.selectedOption = 'planning'
              // Prevent Row onlcik
              event.stopPropagation()
            }}>
              <PlannedOnInformation
                productionRequest={productionRequest}
              />
            </div>
          )
        },
        sortKey: ['start_at'],
        cellProps: { collapsing: true },
      },
      {
        attr: (productionRequest) => (
          <ProductionRequestReduceQuantityModal
            productionRequest={productionRequest}
            trigger={props => (
              <div data-test-show-reduce-quantity-modal {...props}>
                <LiveProgress withTotal
                  productionRequest={productionRequest}
                />
              </div>
            )}
          />
        ),
        label: '#' + t('productionRequest.field.quantity.label'),
        collapsing: true,
        sortKey: ['quantity'],
      },
      {
        attr: (productionRequest) => (
          <ProductionRequestProgress includeQuantityDone
            productionRequest={productionRequest}
            onSelect={this.workStationCode ? () => this.onStartPerforming(productionRequest) : undefined}
            workStationCode={this.workStationCode}
            groupBy={this.groupBy}
            showLabels={this.showLabels}
          />
        ),
        label: t('productionRequest.overview.processSteps'),
        sortKey: ['production_line_names'],
      },
      '',
    ]
  }
  @computed get settings() {
    return this.baseSettings();
  }

  buttons = [
    (productionRequest) => (
      <ItemButton data-test-production-request-progress-button
        primary={false}
        icon="square-info"
        size="large"
        style={{ 'margin-right': '8px' }}
        onClick={() => (this.selectedProductionRequest = productionRequest)}
      />
    ),
    (productionRequest) => this.workStationCode && (
      <Button data-test-production-request-perform
        primary
        icon="play"
        size="large"
        onClick={(e) => {
          // Hack to prevent triggering the onclick of the whole row when clicking on the button.
          e.stopPropagation()
          this.onStartPerforming(productionRequest)
        }}
      />
    ),
  ]

  @action fetch(...args) {

    const oldParams = this.store.params

    const now = DateTime.now()
    // start date filter
    if (this.store.params.start_date !== undefined) {
      this.store.params = {
        ...this.store.params,
        '.start_date:gte': now.minus({ days: this.store.params.start_date }).toISODate()
      };
      delete this.store.params.start_date;
    }

    if (this.store.params.end_date !== undefined) {
      this.store.params = {
        ...this.store.params,
        '.start_date:lte': now.plus({ days: this.store.params.end_date }).toISODate()
      };
      delete this.store.params.end_date;
    }

    // workstation code filter
    this.store.params = {
      ...this.store.params,
      '.process_version.batch_type.type:not:in': 'buy,sell,transfer_line',
      ...this.workStationCode ? {
        '.released': true,
        '.work_station_code': this.workStationCode,
      } : {},
    }
    try {
      return super.fetch(...args)
    } finally {
      this.store.params = oldParams
    }
  }
  /**
   * Renders a button that applies a range filter on the start day of the orders that is relative to the current day.
   * @param  {Number} start_date  Lower bound of the range of the filter as distance from today in days. can be negative.
   * @param  {Number} end_date    Upper bound of the range of the filter as distance from today in days. can be negative.
   * @param  {String} label       Label of the button.
   * @param  {Props}  props       Optional props to attatch to the button.
   * @return {Button}             Rendered button
   *
   * Start and end dates can also be negative, meaning that the range of days doesn't have to include today.
   * If filled in as undefined, that side of the range is infinite.
   * Both ends of the range are inclusive.
   * Example: start_date=1, end_date=3 means a range starting from yesterday untill 3 days from now (inclusive)
   * Example: start_date=undefined, end_date=-1 means a range from the begining of time until yesterday (inclusive)
   */
  renderReceiptStatusButton(start_date, end_date, label, props = {}) {
    // a filter is active if it doesn't have a requirement for a date or if that requirement is met
    const checked = this.store.params.start_date === start_date && this.store.params.end_date === end_date

    return (
      <Button
        fluid
        content={label}
        icon={
          checked
            ? 'check square'
            : 'square outline'
        }
        labelPosition="left"
        active={checked}
        onClick={action(() => {
          if (checked) {
            // If check and clicked again, clear both filters
            delete this.store.params.start_date
            delete this.store.params.end_date
          }
          else {
            // if a filter is defined in the button, apply it.
            if (start_date !== undefined) {
              this.store.params.start_date = start_date
            }
            //if it is undefined, clear it
            else {
              delete this.store.params.start_date
            }

            // if a filter is defined in the button, apply it.
            if (end_date !== undefined) {
              this.store.params.end_date = end_date
            }
            //if it is undefined, clear it
            else {
              delete this.store.params.end_date
            }
          }
          this.fetch()
        })}
        {...props}
      />
    )
  }

  @computed get filters() {
    return this.baseFilters()
  }

  // @observable start_date = 'start_date'
  // @observable end_date = 'end_date'

  baseFilters() {
    return [
      {
        type: 'progress',
        label: t(`progress.${window.viewStore.progressScope}Picker`),
        name: '.open',
      },
      {
        // predefined filters for relative day filters
        type: 'custom',
        callback: () => (
          <ButtonField>
            <label>{'Relative filters'}</label>
            {this.renderReceiptStatusButton(0, 0, t('productionRequest.filter.today'), {
              'data-test-danger-count': true,
            })}
            {this.renderReceiptStatusButton(0, 2, t('productionRequest.filter.days_3'), {
              'data-test-warning-count': true,
            })}
            {this.renderReceiptStatusButton(0, 6, t('productionRequest.filter.days_7'), {
              'data-test-warning-count': true,
            })}
            {this.renderReceiptStatusButton(undefined, -1, t('productionRequest.filter.past'), {
              'data-test-normal-count': true,
            })}
          </ButtonField>
        ),
      },
      // Infinitely customizable filter that allows the user to set a range of start dates to show in relation to today.
      // However it was deemed to complex for the user so it needs some polishing before using.
      // If you want to use it, also uncomment start/end_date observables
      // {
      //   type: 'custom',
      //   callback: () => (
      //       <TableRow>
      //         <TableCell collapsing>
      //           <StyledTargetNumberInput
      //             label={<div>Start day from <Popup content='Lower bound on the start day, relative to today. Example: If set to 3, the lower limit will be 3 days ago. If set to -1, the lower limit will be tomorrow.' trigger={<Icon name='info circle' color='grey'/>}/></div>}
      //             target={this.store}
      //             name={this.start_date}
      //             size='small'
      //             allowNegative={true}
      //             afterChange={this.debouncedFetch}
      //           />
      //         </TableCell>
      //         <TableCell collapsing>
      //           <StyledTargetNumberInput
      //             label={<div>Start day to <Popup content='Upper bound on the start day, relative to today. Example: If set to 3, the upper limit will be 3 days from now. If set to -1, the upper limit will be yesterday.' trigger={<Icon name='info circle' color='grey'/>}/></div>}
      //             target={this.store}
      //             name={this.end_date}
      //             size='small'
      //             allowNegative={true}
      //             afterChange={this.debouncedFetch}
      //           />
      //         </TableCell>
      //       </TableRow>
      //   ),
      // },
      { // T43742: erp filter should be at top
        type: 'text',
        label: t('productionRequest.filter.erpId'),
        name: '.erp_number',
      },
      {
        type: 'text',
        label: t('productionRequest.filter.linkedSalesOrder'),
        name: '.linked_sales_order',
      },
      {
        label: t('productionRequest.filter.deliveryDate'),
        type: 'dateRange',
        name: '.out_shipment.sales_order.delivery_date:range',
      },
      {
        type: 'multiPick',
        label: t('productionRequest.filter.requestStatus'),
        name: '.request_status:in',
        options: [
          {
            value: 'todo',
            text: 'todo',
          },
          {
            value: 'in_progress',
            text: 'in progress',
          },
          {
            value: 'complete',
            text: 'complete',
          },
        ],
      },
      {
        type: 'multiPick',
        remote: true,
        label: t('salesOrder.field.customer.label'),
        name: '.flat_out_shipment.sales_order.customer:in',
        store: this.businessRelations,
        toOption: (businessRelation) => ({
          value: businessRelation.id,
          text: businessRelation.name,
        }),
      },
      {
        type: 'multiPick',
        remote: true,
        label: t('purchaseOrder.field.supplier.label'),
        name: '.flat_in_shipment.purchase_order.supplier:in',
        store: this.businessRelations,
        toOption: (businessRelation) => ({
          value: businessRelation.id,
          text: businessRelation.name,
        }),
      },
      {
        type: 'multiPick',
        remote: true,
        label: t('productionRequest.filter.shippingMethod'),
        name: '.flat_shipping_method:in',
        store: this.shippingMethods,
        toOption: (shippingMethod) => ({
          value: shippingMethod.id,
          text: shippingMethod.code,
        }),
      },
      {
        type: 'custom',
        callback: () => (
          <TargetSelect
            search
            remote
            multiple
            label={t('productionLineVersion.field.productionLine.label')}
            store={this.productionLineStore}
            target={this.store}
            name=".process_version.steps.work_station.production_line_version.production_line.id:in"
            size="small"
            toOption={(productionLine) => ({
              value: productionLine.id,
              text: productionLine.name,
            })}
            afterChange={this.debouncedFetch}
          />
        ),
      },
      {
        type: 'custom',
        callback: () => (
          <TargetSelect
            multiple
            search
            label={t('progress.processType.label')}
            target={this.store}
            name=".process_version.batch_type.type:in"
            size="small"
            options={PROCESS_TYPES.map((type) => ({
              value: type,
              text: t(`progress.processType.value.${type}`),
            }))}
            afterChange={this.debouncedFetch}
          />
        ),
      },
      {
        type: 'text',
        label: t('productionRequest.field.articleType.label'),
        name: '.article_type_search',
      },
      {
        type: 'multiPick',
        label: t('productionRequest.filter.itemGroup'),
        name: '.process_version.batch_type.article_type.classification:in',
        remote: true,
        store: this.classificationStore,
        toOption: (classification) => ({
          value: classification.id,
          text: classification.name,
        }),
        searchKey: '.name:icontains',
      },
      {
        type: 'select',
        name: '.flat_project:in',
        label: t('productionRequest.filter.projectCode'),
        remote: true,
        multiple: true,
        searchKey: '.code:icontains',
        store: new ProjectStore(),
        toOption: (project) => ({
          value: project.id,
          text: project.code,
        }),
      },
      () => markedColorFilter('.deep_marked_colors:contains'),
      {
        type: 'text',
        label: t('productionRequest.filter.subProductionRequestMetavalues'),
        name: '.batches.sub_production_request_batch_links.sub_production_request.batches.metavalues.value:has_key'
      },
      {
        type: 'custom',
        callback: () => <Divider horizontal>IDs</Divider>,
      },
      {
        type: 'text',
        label: t('productionRequest.field.id.label'),
        name: '.id',
      },
      {
        type: 'text',
        label: t('productionRequest.filter.nestId'),
        name: '.flat_nest_id',
      },
      {
        type: 'text',
        label: t('productionRequest.overview.productionOrderNumber'),
        name: '.production_order.id',
      },
      {
        type: 'text',
        label: t('productionRequest.filter.inShipmentId'),
        name: '.flat_in_shipment.id',
      },
      {
        type: 'text',
        label: t('productionRequest.filter.outShipmentId'),
        name: '.flat_out_shipment.id',
      },
      {
        type: 'text',
        label: t('productionRequest.filter.warehouseTransferID'),
        name: '.flat_warehouse_transfer.id',
      },
      {
        type: 'custom',
        callback: () => <Divider horizontal>Group By</Divider>,
      },
      {
        type: 'custom',
        callback: () => (
          <TargetSelect
            noLabel
            name="groupBy"
            size="small"
            value={this.groupBy}
            onChange={(groupBy) => {
              this.groupBy = groupBy
              localStorage.setItem(GROUP_BY_KEY, groupBy)
            }}
            options={[
              { value: 'workStation', text: t('progress.groupBy.value.workStation') },
              { value: 'step', text: t('progress.groupBy.value.step') },
            ]}
          />
        ),
      },
    ]
  }

  toolbar = [
    () => this.props.viewStore.isWorkStation && (
      <ScanToPerformModal
        history={this.props.history}
        operator={this.scannedOperator}
        triggerProps={{
          content: t('batch.scanToPerformModal.trigger'),
          icon: 'barcode-read',
          labelPosition: 'left',
        }}
      />
    ),
  ]

  // A hack to show these buttons to the left of the toolbar
  renderPaginationControls() {
    return (
      <>
        {super.renderPaginationControls()}
        <span style={{ 'margin-left': '20px' }}></span>
        <FindBatchOrArticleTypeModal
          onFound={(batchOrArticleType) => {
            this.batchOrArticleTypeToShowInfo = batchOrArticleType
          }}
        />
        <span style={{ 'margin-left': '10px' }}></span>
        <AddStockCountModal
          afterSave={(productionRequest) => {
            const { viewStore, history } = this.props
            if (viewStore.isWorkStation) {
              history.push(`/operations/production-request/${productionRequest.id}/perform`)
            } else {
              this.fetch()
            }
          }}
        />
      </>
    );
  }


  afterFetch() {
    if (this.workStationCode) {
      this.store.models = workStationActiveOrders(this.store, this.workStationCode)
    }

    this.workStationsFte = null
    if (this.period !== null) {
      return this.store.wrapPendingRequestCount(getWorkStationsFte(this.store.models, this.period).then(res => {
        this.workStationsFte = res
      }))
    }
  }

  performanceSubscriptions = {}

  fetchOnMount() {
    return this.workStationCode || super.fetchOnMount()
  }


  componentDidMount() {
    super.componentDidMount()
    // Get settings for color names
    const cmn = new GlobalValue({ key: 'color_marking_names' })
    cmn.fetch().then(() => {
      this.colorMarkingNames = JSON.parse(cmn.value)

      this.bulkActions = [
        ...MARKED_COLORS.map(color => markedColorReactionMap(color, this.colorMarkingNames)),
      ]
    })

    this.workStation = this.props.workStation;
    this.workStationCode = this.workStation?.code;

    this.getScannedOperator()

    this.isWorkStationReaction = reaction(
      () => (
        this.workStationCode
          ? this.store.models.map(({ id }) => id).sort().join(',') // In a sorted string so that we only update when its really different
          : '*'
      ),
      (ids) => {
        ids = ids === '' ? [] : ids.split(',')
        // Unsubscribe from old subscriptions

        // eslint-disable-next-line
        for (const [id, subscription] of Object.entries(this.performanceSubscriptions)) {
          if (!ids.includes(id)) {
            subscription.unsubscribe()
            delete this.performanceSubscriptions[id]
          }
        }
        // Subscribe to new subscriptions
        // eslint-disable-next-line
        for (const id of ids) {
          if (this.performanceSubscriptions[id] === undefined) {
            this.performanceSubscriptions[id] = this.store.subscribeToPerformances(id === '*' ? '*' : parseInt(id), { removeFinalized: true })
          }
        }
      },
      { fireImmediately: true },
    )
    // Auto expand the main order to select next line
    this.selectedReaction = reaction(
      () => !this.store.isLoading && this.store.length > 0 && this.selectedId,
      action((selectedId) => {
        if (selectedId) {
          this.selected = this.store.find((pr) => pr.id === parseInt(selectedId))
        }
      }),
      { fireImmediately: true },
    )
  }

  componentWillUnmount() {
    super.componentWillUnmount()

    // eslint-disable-next-line
    for (const subscription of Object.values(this.performanceSubscriptions)) {
      subscription.unsubscribe()
    }
    this.selectedReaction()
  }

  @computed get groupLeaderIDs() {
    let groupLeaderIDs = []
    this.store.models.filter(item => item.groupLeader).forEach(item => groupLeaderIDs.push(item.groupLeader))
    return groupLeaderIDs
  }

  isGroupLeader(productionRequest) {
    return productionRequest.groupLeader === null && this.groupLeaderIDs.includes(productionRequest.id)
  }

  groupItems(productionRequest) {
    return this.store.filter((p) => p.groupLeader === productionRequest.id || p.id === productionRequest.id)
  }

  renderGroupItem(item, i, classNames = []) {
    return (
      <tr
        key={item.cid}
        className={classNames.join(' ')}
        {...this.rowProps(item, i)}
      >
        {this.mappedSettings.map((setting, i) => this.renderCell.bind(this)(
          item, setting, i,
        ))}
        {this.finalButtons.length > 0 && (
          <Table.Cell collapsing singleLine textAlign="right">
            {this.finalButtons.map((button, i) => this.renderButton.bind(this)(
              item, button, i + this.mappedSettings.length,
            ))}
          </Table.Cell>
        )}
      </tr>
    )
  }

  renderGroupLeader(productionRequest, showGroup) {
    const groupItems = this.groupItems(productionRequest)
    // const totalProcessTime = groupItems.map(item => item.totalProcessTime).reduce((a, b) => a + b, 0)
    const totalQuantity = groupItems.map(item => item.quantity).reduce((a, b) => a + b, 0)
    return (
      <GroupRow open={showGroup}>
        <Table.Cell>
          <Icon data-test-chevron
            name={productionRequest.id === this.selectedGroup ? 'chevron down' : 'chevron right'}
            onClick={() => this.selectedGroup = productionRequest.id === this.selectedGroup ? null : productionRequest.id}
          />
        </Table.Cell>
        <Table.Cell data-test-group-description singleLine><i><b>Group: {' '}</b></i>{productionRequest.groupDescription}</Table.Cell>
        {range(1, 6).map((cell) => <Table.Cell></Table.Cell>)}
        <Table.Cell><Label size='large' color='#EEEEEE'>{formatPeriod(productionRequest.period)}</Label></Table.Cell>
        <Table.Cell collapsing><b>{totalQuantity}</b></Table.Cell>
        {range(1, 3).map((cell) => <Table.Cell></Table.Cell>)}
      </GroupRow>
    )
  }

  renderRow(productionRequest, i) {
    const isLeader = this.isGroupLeader(productionRequest)
    const showGroup = isLeader && this.selectedGroup === productionRequest.id
    const ungrouped = !productionRequest.groupLeader && this.groupLeaderIDs.indexOf(productionRequest.id) === -1
    const groupItems = this.groupItems(productionRequest)

    // TODO: This is horrible and needs refactoring
    if (this.selectedOption === 'planning' && this.selected?.id === productionRequest?.id) {
      const superRow = super.renderRow(productionRequest, i);
      return (
        <>
          {superRow}
          <Table.Row>
            <Table.Cell colSpan={this.settings.length}>
              <TimingGanttChart productionRequest={productionRequest} />
            </Table.Cell>
          </Table.Row>
        </>

      )
    }

    return (
      <React.Fragment key={productionRequest.id}>
        {isLeader && this.renderGroupLeader(productionRequest, showGroup)}
        {showGroup && groupItems.map((item, i) => this.renderGroupItem(item, i, ['groupMember', ...i === groupItems.length - 1 ? ['borderMember'] : []]))}
        {ungrouped && super.renderRow(productionRequest, i)}
        {productionRequest.id === this.selected?.id && (
          <Table.Row>
            <ProgressLinesDetails
              history={this.props.history}
              productionRequest={productionRequest}
              warning={this.prWarning(productionRequest)}
              colSpan={this.settings.length}
              showLabels={this.showLabels}
              store={this.selected.store}
            />
          </Table.Row>
        )}
      </React.Fragment>
    )
  }

  @computed get week() {
    const param = this.store.params['.open']
    if (param === undefined || !/^\d{4}-W(\d{2}|\d{1})$/.test(param)) {
      return null
    }
    const [year, week] = param.split('-W')
    return { year: parseInt(year), week: parseInt(week) }
  }

  @computed get month() {
    const param = this.store.params['.open']
    if (param === undefined || !/^\d{4}-\d{2}$/.test(param)) {
      return null
    }
    const [year, month] = param.split('-')
    return { year: parseInt(year), month: parseInt(month) }
  }

  @computed get date() {
    const param = this.store.params['.open']
    if (param === undefined || !/^\d{4}-\d{2}-\d{2}$/.test(param)) {
      return null
    }
    const [year, month, date] = param.split('-')
    return { year: parseInt(year), month: parseInt(month), date: parseInt(date) }
  }

  @computed get period() {
    if (this.store.params['.open'] === undefined) {
      return null
    }
    if (window.viewStore.progressScope === 'week') {
      return this.week //`${this.week.year}-W${this.week.week}`
    } else if (window.viewStore.progressScope === 'month') {
      return this.month //`${this.month.year}-${this.month.month}`
    } else if (window.viewStore.progressScope === 'date') {
      return this.date //`${this.date.year}-${this.date.month}-${this.date.date}`
    }
    return null
  }

  @action onProductionRequestAdd({ data: { id, process_version, production_line_version, production_line } }) {
    const pl = this.productionLineStore.get(production_line)
    if (!pl) {
      this.productionLineStore.add({ id: production_line }).fetch()
      return
    }
    const plv = pl.versions.get(production_line_version)
    if (!plv) {
      pl.versions.add({ id: production_line_version }).fetch()
      return
    }
    const pv = plv.processVersions.get(process_version)
    if (!pv) {
      plv.processVersions.add({ id: process_version }).fetch()
      return
    }
    const pr = pv.productionRequests.get(id)
    if (!pr) {
      pv.productionRequests.add({ id }).fetch()
      return
    }
  }

  @action onProductionRequestChange({
    data: {
      id,
      process_version,
      production_line_version,
      production_line,
      quantity,
    },
  }) {
    const pr = this.store.get(id)

    if (pr) {
      pr.setInput('quantity', quantity)
    }
  }

  @action onProductionRequestDelete({ data: { id, process_version, production_line_version, production_line } }) {
    this.store.removeById(id)
  }

  renderTitleRight() {
    return (
      <>
        <Checkbox toggle
          label={'Show step labels ? '}
          checked={this.showLabels}
          onChange={(e, { checked }) => {
            this.showLabels = checked
            localStorage.setItem(SHOW_STEP_LABELS_KEY, this.showLabels)
          }}
        />
        {super.renderTitleRight()}
      </>
    )
  }


  getDefaultParams() {
    const params = { ...super.getDefaultParams() }
    if (window.viewStore.progressScope === 'week') {
      const { year, week } = currentWeek()
      params['.open'] = `${year}-W${week}`
    } else if (window.viewStore.progressScope === 'month') {
      const { year, month } = currentMonth()
      params['.open'] = `${year}-${month.toString().padStart(2, '0')}`
    } else if (window.viewStore.progressScope === 'day') {
      const { year, month, day } = currentDate()
      params['.open'] = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`
    } else if (window.viewStore.progressScope === 'none') {
      params['.open'] = 'none'
    } else {
      throw new Error(`unknown progress scope: ${window.viewStore.progressScope}`)
    }
    return params
  }

  renderOverviewTable() {
    return (
      <>
        {super.renderOverviewTable()}
        {/* For when batchOrArticleTypeToShowInfo is a Batch. */}
        {this.batchOrArticleTypeToShowInfo instanceof Batch && (
          <TargetInfoModal
            open
            target={this.batchOrArticleTypeToShowInfo}
            onClose={() => (this.batchOrArticleTypeToShowInfo = null)}
          />
        )}
        {/* For when batchOrArticleTypeToShowInfo is an ArticleType. */}
        {this.batchOrArticleTypeToShowInfo instanceof ArticleType && (
          <ArticleTypeInfoModal
            open
            articleType={this.batchOrArticleTypeToShowInfo}
            onClose={() => (this.batchOrArticleTypeToShowInfo = null)}
            globalValueDecimalRounding={this.globalValueDecimalRounding}
          />
        )}
        {!this.batchOrArticleTypeToShowInfo && this.selectedProductionRequest && (
          <ProductionRequestModal
            productionRequestId={this.selectedProductionRequest.id}
            processVersion={this.selectedProductionRequest.processVersion}
            onBatchSelect={(batch) => (this.batchOrArticleTypeToShowInfo = batch)}
            onClose={() => (this.selectedProductionRequest = null)}
          />
        )}
      </>
    )
  }
}
